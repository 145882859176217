document.querySelectorAll('.product__article_link').forEach(el=>{
    el.addEventListener('click', e=>{
        e.preventDefault()
        if(e.target.closest('.product__article').classList.contains('_active')) {
            e.target.closest('.product__article').classList.remove('_active')
        }else {
            document.querySelectorAll('.product__article').forEach(item=>{
                item.classList.remove('_active')
            })
            e.target.closest('.product__article').classList.add('_active')
            
            const elementPosition = e.target.getBoundingClientRect().top;
            const offsetPosition = elementPosition - 65;

            window.scrollBy({
                top: offsetPosition,
                behavior: 'smooth'
            }); 
        }        
    })
})


